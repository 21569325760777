<template>
  <div class="j-mshe-container user-container">
    <v-header v-if="isShowHeaderNav" />
    <t-and-c />
    <router-view v-slot="{ Component }">
      <keep-alive
        :include="keepAliveList"
        :exclude="excludeList"
        :max="3"
      >
        <component v-if="!excludeList.includes(Component?.type.name)" :is="Component" />
      </keep-alive>
    </router-view>

    <OrderMaskDialog />
    <!-- loading -->
    <UserLoading />
    <!-- 信息提示 -->
    <UserMsgModal />
    <UserConfirmModal />
  </div>
</template>

<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { mapMutations } from 'vuex'
import { isUnDef } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
// 原app的index.js的引用
import './analysis'
import { handlePrefetchResource } from './prefetch'
import asyncStreamCenter from './service/asyncStreamCenter'
import UserInfoManager from 'public/src/services/UserInfoManager'
// import { unstable_scheduleCallback, unstable_LowPriority } from 'scheduler'
asyncStreamCenter.init({ scene: 'user-container' })

const { WEB_CLIENT, langPath } = gbCommonInfo
import { getCartBaseInfo } from 'public/src/pages/cart_v2/components/business/services.js'

// const triggerEffect = (fn) => {
//   unstable_scheduleCallback(unstable_LowPriority, () => {
//     fn()
//   })
// }

export default defineComponent({
  name: 'PageContainer',
  components: {
    // vHeader,
    vHeader: defineAsyncComponent(() => import( /* @vite-ignore */ '../components/header.vue')),
    // TAndC: defineAsyncComponent(() => import(/* @vite-ignore */ 'public/src/pages/components/coupon/mall/tAndc.vue')),
    // OrderMaskDialog: defineAsyncComponent(() => import( /* webpackChunkName: "order-mask-dialog" */ /* @vite-ignore */ './components/OrderMaskDialog.vue')),
    // UserMsgModal: defineAsyncComponent(() => import( /* webpackChunkName: "user-msg-modal" */ /* @vite-ignore */ './components/UserMsgModal.vue')),
    // UserLoading: defineAsyncComponent(() => import( /* webpackChunkName: "user-loading" */ /* @vite-ignore */ './components/UserLoading.vue')),
    // UserConfirmModal: defineAsyncComponent(() => import( /* webpackChunkName: "user-confirm-modal" */ /* @vite-ignore */ './components/UserConfirmModal.vue')),
    // vHeader: () => asyncStreamCenter.register(() => import( /* @vite-ignore */ '../components/header.vue'), { scene: 'user-container' }),
    TAndC: defineAsyncComponent(() => asyncStreamCenter.register(() => import(/* @vite-ignore */ 'public/src/pages/components/coupon/mall/tAndc.vue'), { scene: 'user-container' })),
    OrderMaskDialog: defineAsyncComponent(() => asyncStreamCenter.register(() => import( /* webpackChunkName: "order-mask-dialog" */ /* @vite-ignore */ './components/OrderMaskDialog.vue'), { scene: 'user-container' })),
    UserMsgModal: defineAsyncComponent(() => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-msg-modal" */ /* @vite-ignore */ './components/UserMsgModal.vue'), { scene: 'user-container' })),
    UserLoading: defineAsyncComponent(() => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-loading" */ /* @vite-ignore */ './components/UserLoading.vue'), { scene: 'user-container' })),
    UserConfirmModal: defineAsyncComponent(() => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-confirm-modal" */ /* @vite-ignore */ './components/UserConfirmModal.vue'), { scene: 'user-container' })),
  },
  beforeRouteLeave (to, from, next) {
    // 关闭快速加车弹窗
    // this.closeQuickAdd()
    if (document?.getElementById('app')) {
      document.getElementById('app').classList.remove('user-center-height');
    }
    if (document?.getElementById('in-app')) {
      document.getElementById('in-app').classList.remove('user-center-height');
    }
    requestAnimationFrame(() => {
      next()
    })
  },
  beforeRouteEnter (to, from, next) {
    if (document?.getElementById('app')) {
      document.getElementById('app').classList.add('user-center-height');
    }
    if (document?.getElementById('in-app')) {
      document.getElementById('in-app').classList.add('user-center-height');
    }
    next()
  },
  data () {
    return {
      langPath,
      keepAliveList: [
        'support',
        'UserGiftcardOrdersList',
        'WishlistGroupName',
        'SharGroupName',
        'WishlistListName',
        'UserOrdersList',
        'UserIndex'
      ], // 缓存组件name集合
      giftCardNewStyle: '',
      // 由于用户线的站内信和详情路由与个人中心存在冲突，
      // 个人中心keepAlive之后，打开站内信路由个人中心也会被推入造成异常
      // 将相关冲突路由排除
      excludeList: [
        'MesIndex',
        'MesDetail'
      ]
    }
  },
  computed: {
    isNewGiftCardStyle () {
      return this.giftCardNewStyle == 1 && (this.$route.path === `${this.langPath}/user/gift_card`)
    },
    isShowHeaderNav () {
      return (this.$route.path !== `${langPath}/user/coupon` && this.$route.path !== `${langPath}/user/payment`) && !this.isNewGiftCardStyle
    },
  },
  watch: {
    $route (to, from) {
      if (to.meta.pageType == 'userPage') {
        // hanldeRouteChange.call(this, to)
        this.changeHeaderNav({ title: '' })
        if (to.meta && to.meta.navTitle) {
          this.changeHeaderNav({
            title: to.meta.navTitle,
            fromName: from.name
          })
        }
        if (document?.getElementById('app')) {
          document.getElementById('app').classList.add('user-center-height');
        }
        if (document?.getElementById('in-app')) {
          document.getElementById('in-app').classList.add('user-center-height');
        }
      }
    },
    // 'blackMask.showMask'(v) {
    //   if (v && this.blackMask?.type === 'addressError') {
    //     daEventCenter.triggerNotice({ daId: '1-11-1-98' })
    //   }
    // }
  },
  created () {
    this.handlePageTitleChange()
    appEventCenter.on('initUserInfo', () => {
      this.getUserInfo()
      this.fetchCartSum()
      this.refreshCartTagTips()
    })
  },
  mounted () {
    appEventCenter.emit('homeCccxCouponUpdate')
    /* 预取 */
    handlePrefetchResource(this.$el)
    this.fetchUseLanguage()
    // this.firstLoadFn()
    this.changeUserCenterHeight()
    this.fetchGiftBalance()
  },
  activated () {
    this.handlePageTitleChange()
    this.fetchUseLanguage()
    this.getUserInfo()
    this.fetchCartSum(true)
    this.changeUserCenterHeight()
  },
  methods: {
    // ...mapMutations('orderDetail', ['changePageStatus']),
    ...mapMutations('root', [
      'changeHeaderNav',
      // 'changeRootStatus',
      'assignRootState',
      // 'changeBlackMaskStatus'
    ]),
    fetchGiftBalance () {
      schttp({
        method: 'POST',
        url: '/user-api/gift_card/balance',
        useBffApi: true
      }).then(res => {
        this.assignRootState({ newGiftCardBalance: res.info })
        this.giftCardNewStyle = res.info?.new_style
      })
    },
    changeUserCenterHeight () {
      nextTick(() => {
        const tabListUser = document?.querySelector('.j-index-tab-list-user');
        if (tabListUser) {
          tabListUser.classList.add('title-active');
          Array.from(tabListUser.parentNode.children).forEach(child => {
            if (child !== tabListUser) {
              child.classList.remove('title-active');
            }
          });
        }
        if (document?.getElementById('app')) {
          document.getElementById('app').classList.add('user-center-height');
        }
        if (document?.getElementById('in-app')) {
          document.getElementById('in-app').classList.add('user-center-height');
        }
      })
    },
    handlePageTitleChange() {
      const route = this.$route
      this.changeHeaderNav({ title: '' })
      if (route.meta && route.meta.navTitle) {
        this.changeHeaderNav({
          title: route.meta.navTitle,
          fromName: ''
        })
      }

      if (!isUnDef(route.meta && route.meta.isHideBack)) {
        this.changeHeaderNav({
          isHideBack: !!route.meta.isHideBack
        })
      }
    },
    // ...mapMutations('orderReturn', ['scrollToElement']),
    async getUserInfo () {

      const userInfo = await UserInfoManager.get({ key: 'UserInfo', actionType: 'getUserInfo' }) || {}

      this.assignRootState({
        userInfo
      })
      window.gbUserInfo = userInfo
    },
    async fetchCartSum (noChangeRoot) {
      if(this.$route?.name === 'cart') return
      const json = await getCartBaseInfo()
      if (json.code == 0 && json.info) {
        if(!noChangeRoot) {
          let cartInfo = {
            sum: json.info.cartSumQuantity || 0,
            carts: json.info.carts || [],
            info: json.info || {},
            language: json.cartLanguage || {}
          }
          window._gb_app_?.$store?.commit('changeCartInfo', cartInfo)
        }
        let headerCartNum = document.querySelector('.j-header-cart-num');
        if (json.info.cartSumQuantity > 0 && headerCartNum) {
          headerCartNum.classList.remove('mshe-hide');
          headerCartNum.innerHTML = json.info.cartSumQuantity;
        }
        this.changeHeaderNav({
          cart: {
            sum: json.info.cartSumQuantity
          }
        })
      }
    },
    refreshCartTagTips() {
      window._gb_cart_tag_tips_?.refresh?.()
    },
    fetchUseLanguage () {
      schttp({
        method: 'GET',
        url: '/api/common/language/get',
        params: { page: 'user_center' }
      }).then(({ language }) => {
        this.assignRootState({ userCenterLanguage: language })
      })
    },
  }
})
</script>

<style lang="less">
.user-center-height{height: 100%}
.user-container {
  height: 100%;
}
.order-mask-dialog {
  .mb-12 {
    margin-bottom: 0.32rem;
  }
}
</style>
